import { useStores } from "@hooks/use-stores"
import ChangePasswordForm from "@modules/Auth/Admin/components/ChangePasswordForm"
import { navigate } from "gatsby"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"

import styles from "./style.module.css"

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const { auth } = useStores()

  const token =
    typeof window !== "undefined" && window.location
      ? window.location.search.split("=")[1]
      : ""

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleRecoverPassword = async (password) => {
    setLoading(true)
    return await auth
      .changePassword({ token, password })
      .then(() => {
        navigate("/admin")
      })
      .catch((error) => {
        setMessage(error.message)
      })
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <ChangePasswordForm
          handleRecoverPassword={handleRecoverPassword}
          message={message}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default observer(ForgotPassword)
