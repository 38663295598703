import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import React from "react"

import styles from "../../style.module.less"

const ChangePasswordForm = (props) => {
  const [form] = Form.useForm()

  const { handleRecoverPassword, message, loading } = props

  const VALIDATE_MESSAGES = {
    required: "Campo obrigatório",
    string: {
      min: "A senha deve ter no mínimo 6 caracteres!",
    },
  }

  const handlePassword = (props) => {
    handleRecoverPassword(props.newPassword)
  }

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve()
      }
      return Promise.reject("As duas senhas que você digitou não coincidem!")
    },
  })

  return (
    <div className={cx("changePasswordForm", styles.mainContainer)}>
      <span className={styles.modalTitle}>Alterar senha</span>
      <p className={cx(styles.descriptionContainer, styles.margin)}>
        <span>Sua nova senha deve conter letras e números.</span>
      </p>
      <div className={cx(styles.label)}>
        <span>Nova senha</span>
      </div>
      <Form
        form={form}
        onFinish={handlePassword}
        /*  validateMessages={VALIDATE_MESSAGES} */
      >
        <Form.Item
          name="newPassword"
          validateStatus={"error"}
          validateFirst
          rules={[
            {
              type: "string",
              required: true,
              min: 6,
            },
            {
              pattern:
                /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
              message: "A senha deve conter letras e números",
            },
          ]}
        >
          <Input.Password
            disabled={loading}
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
          />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          validateStatus={"error"}
          rules={[validateConfirmPassword]}
        >
          <Input.Password
            disabled={loading}
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
          />
        </Form.Item>
        {message && (
          <div className="mb24 tc">
            <span className="f14 b red">{message}</span>
          </div>
        )}
        <Form.Item className="tc">
          <Button
            className="mt32"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ChangePasswordForm
